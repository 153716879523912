import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { OktaWidgetService } from './../okta-widget.service';
import * as globalVars from './../../api_settings/settings';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'ngx-okta-widget',
  templateUrl: './okta-widget.component.html',
  styleUrls: ['./okta-widget.component.scss']
})
export class OktaWidgetComponent implements OnInit {
  @Input()title:string;
  widget:any;
  str = decodeURIComponent(window.location.href);
  baseURL = globalVars.domain;
  redirectUrl = this.str.substr(this.str.indexOf("?fromURI=") + 9);
  
  constructor(
    private oktaWidget: OktaWidgetService,
    private cdRef:ChangeDetectorRef,
    protected ref: NbDialogRef<OktaWidgetComponent>,
    private router: Router,
    private okta: OktaAuthService
    ) { 
    this.widget = this.oktaWidget.getWidget();
  }

  ngOnInit() {
    // this.cdRef.detectChanges();
    console.log("SET URL")
    console.log(globalVars.signInDomain + this.router.url)
    this.okta.setFromUri(globalVars.signInDomain + this.router.url);
    if (this.oktaWidget.rendered){
      console.log("SHOW LOGIN")
      console.log(this.widget);
      console.log(this.widget.redirectUri);
      this.widget.remove();
      this.oktaWidget.renderLogin();
      this.cdRef.detectChanges();
    }else{
      console.log("RENDER LOGIN")
      console.log(this.widget);
      console.log(this.widget.redirectUri);
      this.oktaWidget.renderLogin();
      this.cdRef.detectChanges();
    }
  }

  oktaRedirect():void{
    console.log("REDIRECTING TO OKTA");
    this.okta.login();
    // this.router.navigate(["/"]).then(result=>{window.location.href = this.oktaURL;});
  }

}
