// callback.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { AuthService } from './../pages/wpt/auth/auth.service';
import {Subscription} from 'rxjs';
import * as globalVars from './../pages/wpt/api_settings/settings';

@Component({
  template: `<div>{{error}}</div>`
})
export class OktaCallbackComponent implements OnInit, OnDestroy {
  error: string;
  fromUri:any = null;
  paramSub:Subscription;
  constructor(
    private okta: OktaAuthService,
    private router:Router,
    private route: ActivatedRoute,
    public geoAuth: AuthService) {
      this.get_Params().then( () => {
      try{
        this.fromUri = this.okta.getFromUri();
        if (this.fromUri == null || this.fromUri == undefined){
          console.log("SET");
          this.okta.setFromUri(globalVars.signInDomain + "/");
        }
        console.log("URL");
        console.log(this.fromUri)
      }catch(err){
        console.log(err)
      }
      this.okta.handleAuthentication()
      .then(data => {
        /**
         * Navigate back to the saved uri, or root of application.
         */
        window.location.replace(this.fromUri);
      })
      .catch(e => {
        // this.error = e.toString();
        // this.router.navigate(['/'], { queryParams: { login: 'failed' } });
        try{
          if ('errorCode' in e){
            if (e['errorCode'] == "access_denied"){
              // this.router.navigate(['/'], { queryParams: { aD: 'access_denied' } });
              this.geoAuth.oktaLoginError.next(e);
              this.router.navigate(['/']);
            }
          }else{
            this.geoAuth.oktaLoginError.next(e);
            this.router.navigate(['/']);
          }
        }catch(err){
          console.log("[INFO] No Error Code in Error")
        }
        console.log(e)
      });
      }).catch(err => {
        console.log("NO LOGIN ATTEMPT");
      });
    
  }
  ngOnDestroy(){

  }
  get_Params() {
    return new Promise((resolve, reject) => {
    this.paramSub = this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0){
        resolve();
      }else{
        
        this.router.navigate(['/']);
        reject();
      }
      // resolve();
    }, error => {
      console.log(error);
    });
    });
  }
  async ngOnInit() {
    console.log("CALLBACK START");
    /**
     * Handles the response from Okta and parses tokens.
     */
   
    try{
      this.okta.isAuthenticated().then(data => {
        console.log(data);
        this.geoAuth.isAuthenticated.next(data);
        // this.
      })
    }catch(err){
      console.log(err)
    }
  }
    
}