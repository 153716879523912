import { Injectable, OnInit,  } from '@angular/core';
import { Router } from '@angular/router';
import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js';
import * as globalVars from './../api_settings/settings';

@Injectable({
  providedIn: 'root'
})
export class OktaWidgetService implements OnInit{
  widget: any;
  currentURL: any;
  intervalRoute: any;
  rendered:boolean = false;
  constructor(
    private router: Router,
    // private cdRef:ChangeDetectorRef,
  ) { 
    // console.log("WIDGET INIT")
    this.widget = new OktaSignIn({
      el: "#sign-in-widget",
      baseUrl: globalVars.baseURLOkta,
      clientId: globalVars.clientId, 
      redirectUri: globalVars.redirectUri,
      securityImage: true,
      logo: 'assets/images/OktaLogo.png',
      language: 'en', 
      features: {
        multiOptionalFactorEnroll: true
      },
      i18n: {
        en: {
          // Labels
          'primaryauth.title': 'Geostream Login',
        }
      },
      // i18n: {
      //   //Overrides default text when using English. Override other languages by adding additional sections.
      //   'en': {
      //     'primaryauth.title': 'Sign In',   // Changes the sign in text
      //     'primaryauth.submit': 'Sign In',  // Changes the sign in button
      //     // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
      //     // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
      //   }
      // },
      authParams: {
        pkce: true,
        // responseMode: 'query',
        responseType: 'code',
        issuer: globalVars.baseURLOkta + '/oauth2/default',
        display: 'page',
        scopes: ['openid', 'email', 'profile'],
      },
    });
    
  }
  renderLogin(){
    console.log("RENDER")
    this.widget.renderEl(
      function (res) {
        if (res.status === 'SUCCESS') {
          // this.cdRef.detectChanges();
          this.widget.hide();
        }
      }
    );
    this.rendered = true;
  }
  ngOnInit(){
    this.intervalRoute = setInterval(function(){
      this.currentURL = this.router.url;
    }, 200);
  }
  getWidget() {
    return this.widget;
  }
}
