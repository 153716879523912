// import Auth from '@aws-amplify/auth';
import { Router } from '@angular/router';

// export function checkUser(){
//   return new Promise((resolve, reject) => {
//       Auth.currentAuthenticatedUser({
//         bypassCache: false,  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data,
//       }).then(user => {
//           this.updateUser(user);
//           resolve(user);
//       })
//       .catch(err => {
//         let router:Router;
//         console.log(router)
//         // router.navigate(['']);
//         reject(null);
//       });
//   })
// }

export const gameParams = [{"label": "Casino", "value": "domain"}, {"label":"Lobby", "value":"lobby"}]

export const fontSize = 14;
//
// ===== File globals.ts
//
'use strict';

import { Output } from '@angular/core';
import { HttpHeaders} from '@angular/common/http';

export const oktaURL_dev = "https://geostream-dev.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Okta&client_id=3fui8p1jai2u2eq73nff07ilu&redirect_uri=https://app.localhost:4200/wpt/&scope=email+openid+profile+aws.cognito.signin.user.admin";
export const oktaURL = "https://geostream-dev.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Okta&client_id=4lhkdtaq612ri0e8gjufrd2fqa&redirect_uri=https://d2avx2qt5qf4c1.cloudfront.net/wpt/&scope=email+openid+profile+aws.cognito.signin.user.admin";

// live WPT Stack
// export const administratorName = "geostream_dev_adminstrator"
// export const testerName = "geostream_dev_tester"
// export const roName = "geostream_dev_RO"

// export const clientId = '0oa4lhn9c07A68gLM357'; // DEV GEOSTREAM SITE
// export const redirectUri = 'https://dev.geostream.com.au/implicit/callback'; //DEV GEOSTREAM SITE

// export const signInDomain = "https://dev.geostream.com.au";
// export const s3Upload = 'https://dev.geostream.com.au/';
// let API_KEY = "C7BC2g36g698BrRjswHnN9b3svHXUvAwdX8FGcQj";
// export const domain = "dev.geostream.com.au";
// export const awsAPI = "a4rppqcowd";
// export const awsSTAGE = "dev-v1";
// export const awsREGION = "ap-southeast-2";

// Derv Live Stack
export const administratorName = "geostream_administrator"
export const testerName = "geostream_tester"
export const roName = "geostream_RO"

export const clientId = '0oa3s9fzv6wzxTKG3357'; //GEOSTREAM.COM.AU
export const redirectUri = 'https://geostream.com.au/implicit/callback'; //GEOSTREAM.COM.AU
export const signInDomain = "https://geostream.com.au";
export const s3Upload = 'https://geostream.com.au/';

let API_KEY = 'b4pgrnlntE6BNqJvBIfha1Zdn4i2Ymiy3P1MkfSk';

export const domain = "geostream.com.au";
export const awsAPI = "5jcn2hp90g";
export const awsSTAGE = "v1";
export const awsREGION = "ap-southeast-2";

// local Derv Stack
// export const administratorName = "geostream_local_derv_administrator"
// export const testerName = "geostream_local_derv_tester"
// export const roName = "geostream_local_derv_RO"

// export const clientId = '0oa554l0gdgjBKYoA357'; //DERV LOCALHOST
// export const redirectUri = 'https://app.localhost:4200/implicit/callback'; //ANY LOCALHOST
// export const signInDomain = "https://app.localhost:4200";
// export const s3Upload = 'https://geostream.com.au/';

// let API_KEY = 'b4pgrnlntE6BNqJvBIfha1Zdn4i2Ymiy3P1MkfSk';

// export const domain = "geostream.com.au";
// export const awsAPI = "5jcn2hp90g";
// export const awsSTAGE = "dev-v1";
// export const awsREGION = "ap-southeast-2";

// //  local WPT STACK
// export const administratorName = "geostream_local_administrator"
// export const testerName = "geostream_local_tester"
// export const roName = "geostream_local_RO"
// export const redirectUri = 'https://app.localhost:4200/implicit/callback'; //ANY LOCALHOST
// export const clientId = '0oa3qm308l5EsBQ0c357';
// export const signInDomain = "https://app.localhost:4200";
// export const s3Upload = 'https://dev.geostream.com.au/';
// let API_KEY = "C7BC2g36g698BrRjswHnN9b3svHXUvAwdX8FGcQj";
// export const domain = "dev.geostream.com.au";
// export const awsAPI = "a4rppqcowd";
// export const awsSTAGE = "v1";
// export const awsREGION = "ap-southeast-2";

export const baseURLOkta = 'https://dev-417234.okta.com';

// Header Options for AWS
export let httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': API_KEY,
    'Authorization': ""
  }),
};

// Header Options for Frontend Elements Only
export let httpOptionsFront = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json',
  // 'authorization': 'TOKEN',
}),
};
export function updateHeaders(token: any) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'Authorization': "Bearer " + token
    }),
  };
}



export function updateHeaders2(token: any) {
  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
      'Authorization': "Bearer " + token
    }),
  };
}

// Headers for Second Stack
export let httpOptions2 = {
  headers: new HttpHeaders({
  'Content-Type': 'application/json',
  'x-api-key': API_KEY,
  }),
 };

export const error403 = {
  "name":"Access Denied",
  "statusMessage": "No Access Token or Invalid"
}

//QUICKSIGHT VARS
export const failedTests = [['All', '[ALL]'], ['Successful', 'Successful'], ['Failed', 'Failed']];

export const outliers = [['All', '[ALL]'], ['YES', 'YES'], ['NO', 'NO']];

export const targetCDN = [['All', '[ALL]'],['True', 'TRUE'],['False', 'FALSE']]

// Devices
export const mobileDeviceTypes = ['iOS', 'Android', 'PC'];

// Users
export const userArray = ['SLIPSTREAM', 'DERIVCO'];

// Active or Not Active
export const activeArray = [['Active', 'true'], ['Not Active', 'false']];

// Upload File Types
export const uploadFileTypes = ['.jpg', '.jpeg', '.bmp', '.png'];

// Frequency Times
export const frequencyTimes = ["5", "10", "15", "20", "30", "60"];

// site URL
export const siteURL = 'localhost:4200';

// User Var
export let wptUser: any;
export let wptRole: any;

export let contactAdminEmail = "cdn@tech-connect.com.au";

export let sessionVal: any;

export function updateSession(val: any) {
  sessionVal = val;
}

export function updateUser(val: any) {
  wptUser = val;
}
export function updateRoles(val: any) {
  wptRole = val;
}

// PROBE VARS
export const platforms = [['AWS', 'AWS EC2 AGENT', 'Data Center'], ['PI', 'PI 4 AGENT', 'Mobile'], ['PC', 'LINUX', 'OTHER DEVICE']];
export const types = ['Mobile', 'Data Center', 'OTHER DEVICE'];

// wpt Server Vars
export let probesOnline: any;

export function setProbesOnline(val: any) {
  return new Promise((resolve) => {
    probesOnline = val;
    resolve(true);
  })
}

// Sort Tables
export function sortByKey(array, key) {
  return array.sort(function(a, b) {
      const x = a[key]; const y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export function  DescSortByKey(array, key) {
  return array.sort(function(a, b) {
      const x = a[key]; const y = b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

export function sortCols(array, array2, col) {
  array.forEach(element => {
    if (element[1] == col) {
      if (element[2] != '1') {
        this.sortByKey(array2, element[1]);
        element[2] = '1';
      } else {
        this.DescSortByKey(array2, col);
        element[2] = '2';
      }
    } else {
      element[2] = '0';
    }
  });
  return array2;
}

// Help Links
export const confluenceDomain = "https://tech-connect.atlassian.net/wiki/spaces/GEOS/pages/"

// AUTH/DB PAGES
export const helpRecovery = "2068578436/Recovery"
export const helpProfile = "2068578418/Profile"

// ADMIN PAGES
export const helpCDN = "2068578467/CDNs"
export const helpConnections = "2068578479/Connections"
export const helpDevices = "2068578507/Devices"
export const helpCasino = "1777008705/Casinos"
export const helpLobby = "2068578549/Lobby"
export const helpGame = "2068578560/Games"
export const helpTemplates = "2068578586/Templates"

// PROBE PAGES
export const helpProbes = "2068578637/Probes"
export const helpCreateProbes = "2068578681/Create+Probes"
export const helpProbeMap = "2068578692/Probe+Maps"

// TESTS PAGES
export const helpTests = "2068578714/Tests"
export const helpCreateTests = "2068578725/Creating+Tests"
export const helpDupTests = "2068578765/Duplicate+Tests"
export const helpTestPurge = "2068578811/Test+Purge"
export const helpReRunTest = "2068578841/Re-Run+Tests"
export const helpTestBreakdown = "2068578871/Test+Breakdown"

// RESULTS PAGES
export const helpResults = "2068578903/Results"

// ANALYTICS PAGES
export const helpAnalytics = "2068578914/Analytics"

// DATA USAGE PAGES
export const helpCreateData = "2068578925/Data+Usage"

// USER CREDS PAGES
export const helpUSERCREDS = "2068578925/Data+Usage"

// QS Dashboard ids DEV
// export const homeDashId = '5ee1774f-4cf1-4db1-b398-6cf1cf4477e8'
// export const defaultDashId = 'f287dc02-2ba9-442a-9583-b7cd5c875ce8';
// export const networkDashId = 'e402b567-faea-46e4-928f-37ba5c9154ce';
// export const cdnDashId = 'a9ce4d89-1e99-49dd-adfb-503ee5c61b91';
// export const gameDashId = 'deec5930-8f94-45ef-9b86-8979b57a5b55';
// export const deviceDashId = 'ae82aa9b-f16b-4257-a1a0-2ce3c652f348';
// export const probeDashId = 'c00417cb-2008-4ff9-b4d4-2069ba9d4c88';


// //QS Dashboard ids DEV
// export const homeDashId = '9ed32ee2-fdc8-4db8-b281-76cbdd451c86'
// export const networkDashId = '5fcf44e1-4db9-480f-876f-9d74370b44e1';
// export const cdnDashId = '5b1ad94b-05df-4c9f-bdc3-c2da544e31e2';
// export const gameDashId = '89f54f90-3614-4912-af07-1e497bc9070f';
// export const deviceDashId = '3e664386-e011-47e7-9306-436d872eb5d4';

//QS Dashboard ids DERVICO
export const homeDashId = '62b7f358-85f7-4cc2-b6c7-5e2888c8505c'
export const networkDashId = 'b10562b3-d68f-4e12-aa3e-ce79abd59d39';
export const cdnDashId = '4157efed-531b-49a4-9f89-ba21e79db4ff';
export const gameDashId = 'f8789c45-e053-4eb6-b139-93479c15907e';
export const deviceDashId = '02810893-6dbe-448b-a5a9-2737a5876207';