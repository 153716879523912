import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../../@theme/theme.module';
import { NbDialogModule,  NbTooltipModule  } from '@nebular/theme';

import { OktaWidgetComponent } from './okta-widget/okta-widget.component';
import { OktaWidgetService } from './okta-widget.service';

const MODULES = [
  HttpClientModule,
  ThemeModule,
  NbDialogModule,
  NbTooltipModule
];

const SERVICES = [
  OktaWidgetService,
];

@NgModule({
  imports: [
    MODULES,
   
  ],
  declarations: [
    OktaWidgetComponent
  ]
  ,
  providers: [
    OktaWidgetService,

  ],
  entryComponents: [
    OktaWidgetComponent
  ],
  exports:[ OktaWidgetComponent ]
  ,
})
export class SignInWidgetModule { }
