import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as globalVars from './../api_settings/settings';
import { OktaAuthService } from '@okta/okta-angular';
import { authData } from './../models/authData';
@Injectable({
  providedIn: 'root',
})

export class AuthService {
  // Just needing to push a auth t/f
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  
  // user object
  userInfo: BehaviorSubject<authData> = new BehaviorSubject<authData>(undefined);

  // detect errors to trigger user error flow
  oktaLoginError: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  // If updated api call sent to okta
  updateUserSent:boolean = false;

  // Observable that triggers x seconds to check user details
  userData:Observable<authData>;
  
  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router) {
      // console.log("AUTH SERVICE CONSTRUCT")
      
    // get authentication state for immediate use
    try{
      let x = localStorage.getItem("logErr");
      // console.log(x); 
      if (x != null && x != undefined){
        this.oktaLoginError.next(JSON.parse(x));
        localStorage.removeItem("logErr");
      }
    }catch(err){
      console.log("[ERROR] Local Storage");
      console.log(err);
    }    
    this.userData = new Observable(user => {
      // Define Current Value to Test to push out to subs
      // console.log(user);
      let tempBool:boolean;
      let tempTok:any;
      try {
        // console.log("Observ Init");
        this.oktaAuth.isAuthenticated().then(async (data:boolean) => {
          // console.log("GOT VALUE");
          tempBool = data;
          this.isAuthenticated.next(data);
          
          let newUser = new authData();
          // console.log("%c ---------------------------", "background:black; color:white;");
          // console.log(JSON.stringify(this.userInfo.getValue()));
          newUser.authenticated = data;
          let testAuth:authData = this.userInfo.getValue();
          if (testAuth === undefined || testAuth.groups === undefined){
            // console.log("GET VALUE")
            // console.log("63")
            let x:authData = await this.getUserData(data);
            // console.log(x);
            // console.log("G0T VALUE")
            try{
              newUser.user = x['user'];
              newUser.groups = x['groups'];
              newUser.firstName = x['firstName'];
              newUser.lastName = x['lastName'];
              newUser.email = x['email'];
              newUser.updatedTime = x['updatedTime'];
            }catch(err){
              console.log("[ERROR] UPDATING USER")
            }
          }else{
            // console.log("KEEP")
            newUser = testAuth;
          }
          // console.log("NEWUSER")
          // console.log(newUser)
          this.userInfo.next(newUser);
          // console.log("USER INFO SENT")
          // console.log(JSON.stringify(this.userInfo.getValue()));
          // console.log("%c ---------------------------", "background:black; color:white;");
          user.next(newUser);
        }).catch(err => {
          console.log("[ERR] GET AUTH STATUS INIT");
        });
        setInterval(() => {
          this.oktaAuth.isAuthenticated().then(async (data:boolean) => {
            // console.log(this.userInfo.getValue())
            // console.log("AUTHCHECK")
            // console.log(data)
            // console.log(tempBool)
            if (tempBool != data){
              tempBool = data;
              // console.log(data)
              this.isAuthenticated.next(data);
              let newUser = this.userInfo.getValue();
              // console.log("%c ---------------------------", "background:black; color:white;");
              // console.log(JSON.stringify(this.userInfo.getValue()));
              
              if (!data){
                this.clearUser();
              }else{
                let x:authData = await this.getUserData(data);
                // console.log("104");
                // console.log(x);
                if (Object.entries(x).toString() !== Object.entries(newUser).toString() || x.groups ===  undefined){
                  try{
                    newUser.authenticated = data;
                    newUser.user = x['user'];
                    newUser.groups = x['groups'];
                    newUser.firstName = x['firstName'];
                    newUser.lastName = x['lastName'];
                    newUser.email = x['email'];
                    newUser.updatedTime = x['updatedTime'];
                  }catch(err){
                    console.log("[ERROR] UPDATING USER")
                    console.log(err);
                  }
                  // console.log("NEWUSER")
                  // console.log(newUser);
                  this.userInfo.next(newUser);
               }
              }

              // console.log("USER INFO SENT")
              // console.log(JSON.stringify(this.userInfo.getValue()));
              // console.log("%c ---------------------------", "background:black; color:white;");
              user.next(newUser);
            }
            // Check Local Storage to See if Token has Changed to Update Global Vars
            // This is used as tokens invalidate after x time (defined in okta dev console)
            // try{
            //   this.oktaAuth.getAccessToken().then((data:any) => {
            //     if (tempTok != data){
            //       tempTok = data;
            //       let newUser = new authData();
            //       if (!data){
            //         this.clearUser();
            //       }else{
            //         let x = await this.getUserData(data);
            //         try{
            //           newUser.user = x['user'];
            //           newUser.groups = x['groups'];
            //         }catch(err){
            //           console.log("[ERROR] UPDATING USER")
            //         }
            //       }
            //       this.getUserData(true);
            //     }
            //   }).catch(err => {
            //     console.log("[ERR] GET AT");
            //   });
            // }catch(err){
            //   this.router.navigate(['/']);
            // }
          }).catch(error => {
            console.log("[ERROR] OBSERV LOGIN ERROR SERV");
          });
        }
        , 10000);
      }catch(err){
        user.error(err);
        console.log("%c DIDNT INIT OBSERV", "background:red; color:white;");
        console.log(err);
      }
    });
  }
  getUserData(isAuthenticated:boolean):Promise<authData>{
    // console.log("UPDATE USER DATA");
    return new Promise (async (resolve, reject) => {
     
      if (isAuthenticated){
        // console.log("IS AUTHED");
          try{
            this.updateUserSent = true;
          let user:authData = new authData();
          // console.log("UNDEFINED");
          // console.log(typeof this.userInfo.getValue());
          if (
              (typeof this.userInfo.getValue() === "undefined")
            ){
            // console.log("GETTING USER DATA FROM OKTA")
            await this.oktaAuth.getUser().then((data:any) =>{
              // console.log("%c this is user from okta", "background:green");
              // console.log(data);
              // console.log(JSON.stringify(user));
              user.authenticated = true;
              user.user = data['displayName'] 
              user.groups = data['GroupID'];
              user.firstName = data['firstName'];
              user.lastName = data['lastName'];
              user.email = data['email'];
              user.updatedTime = new Date();
            }).catch(error => {
              console.log("[ERROR] GET USER in Serv");
              if (error['xhr']['responseJSON']['error'] === "invalid_token"){
                error['errorCode'] = "access_denied";
                // console.log(error);
                // this.router.navigate(['/'])
                let loggedInfo = error;
                localStorage.setItem('logErr',JSON.stringify(loggedInfo));
                this.logout(error);
              }
            });
            this.oktaAuth.getAccessToken().then(data =>{
              // console.log(user)
              globalVars.updateHeaders(data);
              globalVars.updateHeaders2(data);

              globalVars.updateUser(user);
              globalVars.updateRoles(user['groups']);
              
              this.userInfo.next(user)
              resolve(user)
            }).catch(error => {
              // console.log("[ERROR] GET ACCESS TOKEN SERV");
              // console.log(error)
              if (error['xhr']['responseJSON']['error'] === "invalid_token"){
                error['errorCode'] = "access_denied";
                console.log(error);
                // this.router.navigate(['/'])
                let loggedInfo = error;
                localStorage.setItem('logErr',JSON.stringify(loggedInfo));
                this.logout(error);
              }
            });
            this.updateUserSent = false;
          }else{
            // HAVE USER DATA 
            // console.log("HAVE USER");
            let minus1 = new Date();
            minus1.setMinutes(minus1.getMinutes() - 1);
            let user:authData = this.userInfo.getValue();
            // console.log("HAS USER DATA");
            // console.log(user);
            // console.log(user.updatedTime <= minus1);
            // console.log( user.groups === undefined);
            // console.log(user.authenticated == false);
            if (user.updatedTime <= minus1 || user.groups === undefined || user.authenticated == false){
              // console.log("GET USER DATA");
              await this.oktaAuth.getUser().then((data:any) =>{
                user.authenticated = true;
                user.user = data['displayName'] 
                user.groups = data['GroupID'];
                user.firstName = data['firstName'];
                user.lastName = data['lastName'];
                user.email = data['email'];
                user.updatedTime = new Date();
              }).catch(error => {
                console.log("[ERROR] GET USER in Serv");
                if (error['xhr']['responseJSON']['error'] === "invalid_token"){
                  error['errorCode'] = "access_denied";
                  // console.log(error);
                  // this.router.navigate(['/'])
                  let loggedInfo = error;
                  localStorage.setItem('logErr',JSON.stringify(loggedInfo));
                  this.logout(error);
                }
              });
              this.oktaAuth.getAccessToken().then(data =>{
                // console.log(user)
                globalVars.updateHeaders(data);
                globalVars.updateHeaders2(data);
  
                globalVars.updateUser(user);
                globalVars.updateRoles(user['groups']);
                
                this.userInfo.next(user)
                resolve(user)
              }).catch(error => {
                console.log("[ERROR] GET ACCESS TOKEN SERV");
                console.log(error)
                if (error['xhr']['responseJSON']['error'] === "invalid_token"){
                  error['errorCode'] = "access_denied";
                  console.log(error);
                  // this.router.navigate(['/'])
                  let loggedInfo = error;
                  localStorage.setItem('logErr',JSON.stringify(loggedInfo));
                  this.logout(error);
                }
              });
            }
          }
        }catch(err){
          console.log(err)
          console.log("error init val")
        }
      }else{
        try{
        // console.log("NOT AUTHED");
        globalVars.updateHeaders(null);
        globalVars.updateHeaders2(null);
        globalVars.updateUser(null);
        globalVars.updateRoles(null);
        let user = new authData();
        user.authenticated = false;
        this.userInfo.next(user);
        // console.log("ROUTING")
        this.isAuthenticated.next(isAuthenticated);
        // clearInterval(this.interval);
        // this.router.navigate(['/'])
        resolve(user);
        }catch(err){
              
          console.log("error not auth")
        }
      }
      });
  }
  login(page:string=null) {
    if (page === null){
      page = '/wpt/agent';
    }
    this.oktaAuth.loginRedirect(page).catch(error => {
      console.log("[ERROR] LOGIN REDIRECT SERV");
    });;
  }
  logout(msgData:any=null) {
    // console.log("CLEAR USER VARS")
    this.clearUser().then(()=>{
      console.log("AUTH SERV LOGOUT")
      this.oktaAuth.logout().catch(error => {
        console.log("[ERROR] LOGOUT SERV");
        if (error['xhr']['responseJSON']['error'] === "invalid_token"){
          error['errorCode'] = "access_denied";
          console.log(error);
          // this.router.navigate(['/'])
          let loggedInfo = error;
          localStorage.setItem('logErr',JSON.stringify(loggedInfo));
          this.logout(error);
        }
      });;
    }).catch(err => {
      console.log("[ERR] LOGOUT");
    });
  }
  clearUser(){
    return new Promise((resolve, reject) => {
      try{
        console.log("%c [INFO] GOODBYE", "Background:red; color:white; font-size:2em; font-weight:bold;");
        globalVars.updateHeaders(null);
        globalVars.updateHeaders2(null);
        globalVars.updateUser(null);
        globalVars.updateRoles(null);
        let user = new authData();
        this.userInfo.next(user);
        this.isAuthenticated.next(false);    
        resolve();
      }catch(err){
        console.log(err);
        reject();
      }
    });
  }
}
