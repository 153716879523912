<div id="sign-in-widget"></div>
<nb-card>
    <button nbButton
                            style="color:#007dc1"
                            type="button"
                            fullWidth
                            (click)="oktaRedirect()">
                            <img src="assets/images/Okta_Logo_White_Medium.png" style="height:20px;" />
                </button>
</nb-card>
