import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
// import { UserData } from '../../../@core/data/users';
import { authData } from './../../../pages/wpt/models/authData';
import { OktaAuthService } from '@okta/okta-angular';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './../../../pages/wpt/auth/auth.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import * as globalVars from '../../../pages/wpt/api_settings/settings';
import { and } from '@angular/router/src/utils/collection';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  AuthSub:Subscription;
  userInfo: any;
  showAlerts:boolean = false;
  @Input() position = 'normal';
  @Input() menuTog = true;

  user: any;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              // private userService: UserData,
              // private analyticsService: AnalyticsService,
              private oktaServ: OktaAuthService,
              public geoAuth: AuthService,
              private layoutService: LayoutService,
              private router:Router,
              private cdref: ChangeDetectorRef
              ) {
                let x = this.geoAuth.userInfo.getValue();
                this.checkAuthGroups(x);

                this.AuthSub = this.geoAuth.userInfo.subscribe((data:authData) => {
                  this.checkAuthGroups(data);
                })
  }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.nick);
    this.cdref.detectChanges();
  }

  checkAuthGroups(data:authData=undefined){
    // console.log("[INFO] HEADER AUTHENTICATED")
    // console.log(data)
    this.userInfo = data;
    try{
      if (this.userInfo.groups.indexOf(globalVars.administratorName) < 0 &&
          this.userInfo.groups.indexOf(globalVars.testerName) < 0){
        this.showAlerts = false;
      }else{
        this.showAlerts = true;
        // console.log("SHOW ALERTS");
      }
    }catch(err){
      this.showAlerts = false;
    }
    // console.log(this.userInfo)
    if (!data){
      this.geoAuth.clearUser();
      // this.router.navigate(['/']);
    }
  }

  ngOnDestroy(){
    if (this.AuthSub != null && typeof this.AuthSub != "undefined"){
      this.AuthSub.unsubscribe();
    }
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    // this.analyticsService.trackEvent('startSearch');
  }
}
